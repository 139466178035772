var firebaseConfig = {
  apiKey: "AIzaSyDE6ZcLHqkngBN9HKj1cHi_Be-b6buOC88",
  authDomain: "bphc-url-shortner.firebaseapp.com",
  projectId: "bphc-url-shortner",
  storageBucket: "bphc-url-shortner.appspot.com",
  messagingSenderId: "300391191961",
  appId: "1:300391191961:web:1a645d61952c5ebda3af08",
  measurementId: "G-PEQP16F1NX"
};
  
export default firebaseConfig;